import React, { createContext, useContext, useState } from 'react';

const PricingContext = createContext();

export const usePricingContext = () => {
  return useContext(PricingContext);
};

export const PricingProvider = ({ children }) => {
  const [values, setValues] = useState([]);
  const [isPricingCanOpen, setIsPricingCanOpen] = useState(true);
  const [count, setCount] = useState(0);
  const [gameCustomTheme, setGameCustomTheme] = useState('');

  const onValuesChange = valueObj => {
    const isExisted = values.find(value => {
      if (value.id === valueObj.id && value.group === valueObj.group) {
        return true;
      } else {
        return false;
      }
    });

    if (
      isExisted &&
      ((typeof valueObj.count === 'number' && !valueObj.count) ||
        typeof valueObj.count === 'undefined')
    ) {
      const updatedValues = values.filter(value => {
        if (value.id !== valueObj.id) {
          return true;
        }
        return false;
      });

      setValues(updatedValues);

      setCount(prevCount => prevCount - 1);
    } else {
      if (valueObj.count) {
        const updatedValues = values.filter(value => {
          if (value.id !== valueObj.id) {
            return true;
          }
          return false;
        });

        setValues([...updatedValues, valueObj]);

        setCount(prevCount => prevCount + 1);
      } else {
        setValues([...values, valueObj]);

        setCount(prevCount => prevCount + 1);
      }
    }
  };

  const closePricing = () => {
    setIsPricingCanOpen(false);
  };

  return (
    <PricingContext.Provider
      value={{
        values,
        onValuesChange,
        isPricingCanOpen,
        closePricing,
        count,
        gameCustomTheme,
        setGameCustomTheme,
      }}>
      {children}
    </PricingContext.Provider>
  );
};
