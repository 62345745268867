import React from 'react';
import { PricingProvider } from './src/components/ServicePage/MobileAppPage/GetPricing/pricingContext';
import './src/styles/global.css';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import gsap from 'gsap';
// import { Power4 } from 'gsap';

export const wrapRootElement = ({ element }) => <PricingProvider>{element}</PricingProvider>;

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.pathname === '/blog/' && location.search) {
    return false;
  }

  return true;
};

export const onRouteUpdate = () => {
  const location = window.location;
  const urlParams = new URLSearchParams(location.search);

  // Capture UTM parameters
  const utm_id = urlParams.get('utm_id');
  const utm_source = urlParams.get('utm_source');
  const utm_medium = urlParams.get('utm_medium');
  const utm_campaign = urlParams.get('utm_campaign');
  const utm_source_platform = urlParams.get('utm_source_platform');
  const utm_term = urlParams.get('utm_term');

  // Store UTM parameters in localStorage (or sessionStorage)
  if (utm_id) {
    localStorage.setItem('utm_id', utm_id);
  }
  if (utm_source) {
    localStorage.setItem('utm_source', utm_source);
  }
  if (utm_medium) {
    localStorage.setItem('utm_medium', utm_medium);
  }
  if (utm_campaign) {
    localStorage.setItem('utm_campaign', utm_campaign);
  }
  if (utm_source_platform) {
    localStorage.setItem('utm_source_platform', utm_source_platform);
  }
  if (utm_term) {
    localStorage.setItem('utm_term', utm_term);
  }

  // window.scrollTo(0, 0);
  setTimeout(() => {
    ScrollTrigger.refresh();
  }, 500);
};

// export { onRenderBody } from './gatsby-ssr';
